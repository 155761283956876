<template>
	<label class="mdc-text-field mdc-text-field--filled" :for="uid" :class="{'mdc-text-field--label-floating': attrs.value, 'mdc-text-field--has-hint': hint, 'mdc-text-field--disabled':attrs.disabled}">
		<span class="mdc-text-field__ripple" v-if="ripple"></span>
		<span class="mdc-text-field__affix mdc-text-field__affix--prefix"><slot name="pre"></slot></span>
		<input class="mdc-text-field__input" :type="type" :name="name" :id="uid" :autocomplete="autocomplete" :placeholder="placeholder" :aria-label="label" :required="required" :aria-required="required" v-bind="attrs" v-on="listeners" @input="input">
		<span class="mdc-floating-label" :class="{'mdc-floating-label--float-above': attrs.value}" :id="`${id}_label`" v-if="label">{{ label }}</span>
		<span class="mdc-text-field__affix mdc-text-field__affix--suffix"><slot name="post"></slot></span>
		<span class="mdc-line-ripple" v-if="ripple"></span>
		<div class="mdc-text-field-helper-line" v-if="hint">
			<div class="mdc-text-field-helper-text" :id="`hint_text_${uid}`" aria-hidden="false" v-html="hint"></div>
		</div>
	</label>
</template>

<script>
import { MDCTextField } from '@material/textfield'
import { v4 } from 'uuid'

export default {
	name: 'textField',
	data: () => ({
		textfield: null,
	}),
	props: {
		autocomplete: String,
		id: String,
		label: String,
		name: String,
		type: String,
		placeholder: String,
		ripple: {
			type: Boolean,
			default: false,
		},
		hint: String,
		// minlength: {
		// 	type: [Number, String],
		// 	default: null,
		// },
		// maxlength: {
		// 	type: [Number, String],
		// 	default: null,
		// },
		small: Boolean,
		required: false,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event)
			}
		},
		hasPre() {
			return Boolean(this.$slots.pre)
		},
		hasPost() {
			return Boolean(this.$slots.post)
		},
		uid() {
			if (!this.id) {
				return v4()
			}
		},
	},
	mounted() {
		this.textfield = new MDCTextField(this.$el)
	},
	methods: {
		input(evt) {
			this.$emit('input', evt.target.value)
		}
	},
}
</script>

<style scoped lang="scss">
.mdc-text-field {
	&--has-hint {

		overflow: visible;
		@include modules.gutter('margin-bottom', 1.5);

		.mdc-text-field-helper-line {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			transform: translateY(90%);
		}

		.mdc-text-field-helper-text {
			color: modules.color_('text');
			opacity: 1;
		}
	}
}
</style>
